import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BlogNavLayout from './BlogNavLayout';
import BlogLandingPage from './BlogLandingPage';
import BlogAboutPage from './BlogAboutPage';
import PrivacyPolicyPage from '../components/@pages/PrivacyPolicyPage';
import TermsOfServicePage from '../components/@pages/TermsOfServicePage';


function AboutSite() {
    return (
        <Routes>
            <Route path="/" element={<BlogNavLayout />}>
                <Route index element={<BlogLandingPage />} />
                <Route path="about" element={<BlogAboutPage />} />
                <Route path="/about/privacy" element={<PrivacyPolicyPage />} />
                <Route path="/about/terms" element={<TermsOfServicePage />} />
                {/* 필요한 다른 라우트들을 추가합니다 */}
            </Route>
        </Routes>
    );
}

export default AboutSite;
