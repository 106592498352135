import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import NavLayout from './components/@layouts/NavLayout';
import ErrorPage from './components/@pages/ErrorPage';
import LandingPage from './components/@pages/LandingPage';
import SignInPage from './components/@pages/SignInPage';
import AboutSite from './about/AboutSite';
import TermsOfServicePage from './components/@pages/TermsOfServicePage';
import PrivacyPolicyPage from './components/@pages/PrivacyPolicyPage';
// import NewProfile from './components/@pages/NewUserPage';
// import SearchInfluencerPage from './components/@pages/SearchInfluencerPage';
// import SearchCampaignPage from './components/@pages/SearchCampaignPage';
// import CampaingLayout from './components/@pages/CampaingLayout';
// import CampaignDetailMain from './components/@pages/CampaignDetailMain';
// import CampaignEditPage from './components/@pages/CampaignEditPage';
// import ProfileLayout from './components/@pages/ProfileLayout';
// import ProfileMain from './components/@pages/ProfileMain';
// import EditProfilePage from './components/@pages/ProfileEditPage';
// import Workspace from './components/@pages/Workspace';
// import WorkspaceMainPage from './components/@pages/WorkspaceMainPage';
// import CampaignManagePage from './components/@pages/CampaignManagePage';
// import PostCampaignPage from './components/@pages/PostCampaignPage';
// import ProtectedRoute from './ProtectedRoute';

function App() {
    const hostname = window.location.hostname;



    return (
      <Router>
        <Routes>
            {hostname.startsWith('about.') ? (
                <Route path="*" element={<AboutSite />} />
            ) : (
              <Route path="/" element={<NavLayout />} errorElement={<ErrorPage />}>
                <Route index element={<LandingPage />} />
                <Route path="signin" element={<SignInPage />} />
                {/*<Route path="new-profile" element={<NewProfile />} />*/}
                {/*<Route path="search-influencer" element={<SearchInfluencerPage />} />*/}
                {/*<Route path="search-campaign" element={<SearchCampaignPage />} />*/}

                {/*<Route element={<ProtectedRoute />}>*/}
                {/*  <Route path="ad/campaign" element={<CampaingLayout />}>*/}
                {/*    <Route index element={<CampaignDetailMain />} />*/}
                {/*    <Route path=":id" element={<CampaignDetailMain />} />*/}
                {/*    <Route path=":id/edit" element={<CampaignEditPage />} />*/}
                {/*    <Route path="edit" element={<CampaignEditPage />} />*/}
                {/*  </Route>*/}

                {/*  <Route path="profile" element={<ProfileLayout />}>*/}
                {/*    <Route index element={<ProfileMain />} />*/}
                {/*    <Route path="edit" element={<EditProfilePage />} />*/}
                {/*    <Route path=":userId" element={<ProfileMain />} />*/}
                {/*    <Route path=":userId/edit" element={<EditProfilePage />} />*/}
                {/*  </Route>*/}

                {/*  <Route path="workspace" element={<Workspace />}>*/}
                {/*    <Route index element={<WorkspaceMainPage />} />*/}
                {/*    <Route path="campaign/:id" element={<CampaignManagePage />} />*/}
                {/*  </Route>*/}
                {/*</Route>*/}
              </Route>
            )}
          {/*<Route path="/post-campaign" element={<PostCampaignPage />} />*/}
        </Routes>
      </Router>
  );
}

export default App;
