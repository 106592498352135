export const landingData = [
    {
        id: 1,
        title: 'Landing Data',
        description: 'Lorem ipsum dolor sit amet',
        image: 'https://cellabor-img.s3.ap-northeast-2.amazonaws.com/web-rsc/home/illustration1.jpeg'

    },
    {
        id: 2,
        title: 'Landing Data',
        description: 'Lorem ipsum dolor sit amet',
        image: 'https://cellabor-img.s3.ap-northeast-2.amazonaws.com/web-rsc/home/illustration2.jpeg'

    },
    {
        id: 3,
        title: 'Landing Data',
        description: 'Lorem ipsum dolor sit amet',
        image: 'https://cellabor-img.s3.ap-northeast-2.amazonaws.com/web-rsc/home/illustration3.jpeg'

    },

];
