// @flow
import React, {useState, useEffect} from 'react';

type Props = {

};
const ErrorPage = (props: Props) => {
    return (
        <div className='flex flex-col justify-center items-center w-full'>
            error
        </div>
    );
};

export default ErrorPage;
