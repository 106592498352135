import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import {LocalizationProvider} from '@mui/x-date-pickers';
// import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
// import 'dayjs/locale/zh-cn';
import {RecoilRoot} from 'recoil';
import {
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import {ThemeProvider} from 'styled-components';
import {theme} from './styles/theme/theme';
// import {NavermapsProvider} from 'react-naver-maps';
import GlobalStyle from './styles/GlobalStyle';




const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient();


root.render(
    <React.StrictMode>
        <RecoilRoot>
            <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                    {/*<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="zh-cn">*/}
                        {/*<NavermapsProvider ncpClientId={`${process.env.REACT_APP_NAVER_MAP_CLIENT_ID}`} submodules={['geocoder']}>*/}
                            <GlobalStyle />
                            <App />
                        {/*</NavermapsProvider>*/}
                    {/*</LocalizationProvider>*/}
                </ThemeProvider>
            </QueryClientProvider>
        </RecoilRoot>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
