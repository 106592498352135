

import { createGlobalStyle } from 'styled-components';
import {theme} from './theme/theme';

const GlobalStyle = createGlobalStyle`

  body {
    background-color: #f2f2f2;
  }
  .markdown-content {
      h1 { font-size: 2em; margin-bottom: 0.5em; }
      h2 { font-size: 1.5em; margin-bottom: 0.5em; }
      h3 { font-size: 1.2em; margin-bottom: 0.5em; }
      p { margin-bottom: 1em; }
      ul, ol { margin-bottom: 1em; padding-left: 2em; list-style: disc }
      li { margin-bottom: 0.5em; }
      blockquote {
          border-left: 4px solid #ccc;
          margin-bottom: 1em;
          padding-left: 1em;
      }
  }
`;

export default GlobalStyle;
