// @flow
import React, {useState, useEffect} from 'react';

type Props = {

};
const BlogLandingPage = (props: Props) => {
    return (
        <div>
            BlogLandingPage
        </div>
    );
};

export default BlogLandingPage;
