// @flow
import React, {useState, useEffect} from 'react';

type Props = {

};
const BlogAboutPage = (props: Props) => {
    return (
        <div>
            BlogAboutPage
        </div>
    );
};

export default BlogAboutPage;
