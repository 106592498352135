// @flow
import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import styled from 'styled-components';

type Props = {};

const LayoutWrapper = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
`;

const Header = styled.header`
    background-color: #f8f9fa;
    padding: 1rem 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
`;

const Nav = styled.nav`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Logo = styled(Link)`
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-decoration: none;
`;

const NavLinks = styled.div`
    display: flex;
    gap: 1rem;
`;

const NavLink = styled(Link)`
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover, &.active {
    background-color: #e9ecef;
  }
`;

const MainContent = styled.main`
  padding: 2rem 0;
`;

const BlogNavLayout = (props: Props) => {
    const location = useLocation();

    const isActive = (path) => {
        if (path === '/') {
            return location.pathname === '/';
        }
        return location.pathname === path;
    };

    return (
        <div>
            <Header>
                <LayoutWrapper>
                    <Nav>
                        <Logo to="/">Blog</Logo>
                        <NavLinks>
                            <NavLink to="/" className={isActive('/') ? 'active' : ''}>
                                Home
                            </NavLink>
                            <NavLink to="/about" className={isActive('/about') ? 'active' : ''}>
                                About
                            </NavLink>
                            <NavLink to="/about/privacy" className={isActive('/about/privacy') ? 'active' : ''}>
                                Privacy Policy
                            </NavLink>
                            <NavLink to="/about/terms" className={isActive('/about/terms') ? 'active' : ''}>
                                Terms of Service
                            </NavLink>
                        </NavLinks>
                    </Nav>
                </LayoutWrapper>
            </Header>
            <LayoutWrapper>
                <MainContent>
                    <Outlet />
                </MainContent>
            </LayoutWrapper>
        </div>
    );
};

export default BlogNavLayout;
