// src/components/PolicyPage.tsx

import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';

interface PolicyPageProps {
    fileName: string;
    title: string;
}

const InfoMdRenderPage: React.FC<PolicyPageProps> = ({ fileName, title }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(`/contents/${fileName}.md`)
            .then(response => response.text())
            .then(text => setContent(text))
            .catch(error => console.error('Error loading markdown file:', error));
    }, [fileName]);

    return (
        <div className="markdown-content">
            <h1>{title}</h1>
            <ReactMarkdown className="markdown-content">{content}</ReactMarkdown>
        </div>
    );
};


export default InfoMdRenderPage;
